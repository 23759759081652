<template>
  <Card v-if="$can('read', 'orders.analytics')">
    <div slot="header">
      <div class="px pt-4">
        <div
          class="is-flex is-align-items-baseline"
          :class="
            hasTitle
              ? 'is-justify-content-space-between'
              : 'is-justify-content-flex-end'
          "
        >
          <span
            v-if="hasTitle"
            class="is-size-4"
          >{{ $tc("graph", 2) }}</span>

          <div class="is-flex">
            <span
              class="is-clickable"
              :class="{ 'has-text-primary': showFilter }"
              @click="showFilter = !showFilter"
            >
              <b-icon
                size="is-small"
                icon="filter"
              />
              {{ $tc("filter", 2) }}
            </span>
            <span class="has-text-grey-lighter mx-2">|</span>
            <DatesPicker
              has-compare
              @change="changeDates"
            />
          </div>
        </div>
        <transition
          name="filter"
          tag="div"
        >
          <div
            v-if="showFilter"
            class="mt-5"
          >
            <FilterOneDate
              v-if="!date_filter_compare"
              :origin-sites="originSites"
              :origin-categories="originCategories"
              :brands-prop="brandsProp"
              :salesmen-prop="salesmen"
              :site-id="siteId"
              :warehouses="warehouses"
              @update="setFilter"
            />
            <FilterCompareDates
              v-else
              :origin-sites="originSites"
              :origin-categories="originCategories"
              :brands-prop="brandsProp"
              :salesmen-prop="salesmen"
              :site-id="siteId"
              :warehouses="warehouses"
              @update="setFilter"
            />
          </div>
        </transition>
      </div>
      <hr class="mb-0 mt-4">
    </div>
    <div class="columns is-mobile is-multiline">
      <div class="column is-2-tablet is-3-mobile">
        <b-select-validation
          v-model="selected_data_type"
          size="is-small"
          required
          :label="$t('type')"
          hide-details
        >
          <template>
            <option
              v-for="opt in data_types"
              :key="opt.id"
              :value="opt.id"
            >
              {{
                opt.text
              }}
            </option>
          </template>
        </b-select-validation>
      </div>
      <div class="column is-6-tablet is-0-mobile px-0" />

      <div
        class="column is-4-tablet is-9-mobile is-flex is-justify-content-flex-end"
      >
        <b-field>
          <p
            v-for="item in dateModes"
            :key="item.text"
            class="control"
          >
            <b-button
              size="is-small"
              :type="item.text == selected_date_mode ? 'is-primary' : ''"
              @click="selected_date_mode = item.text"
            >
              {{ item.text }}
            </b-button>
          </p>
        </b-field>
      </div>
    </div>
    <line-chart
      :options="options"
      :chart-data="datacollection"
    />
    <b-loading
      v-model="loading"
      :is-full-page="false"
    />
  </Card>
</template>

<script>
import debounce from "debounce";
import lineChart from "@/components/Charts/LineChart.vue";
import FilterCompareDates from "./components/FilterCompareDates";
import FilterOneDate from "./components/FilterOneDate";
import DatesPicker from "@/components/forms/elements/DatesPicker";

export default {
  components: {
    DatesPicker,
    lineChart,
    FilterOneDate,
    FilterCompareDates
  },
  props: {
    hasTitle: {
      type: Boolean,
      default: false
    },
    stats: {
      type: Array,
      required: true
    },
    periods: {
      type: Array,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
    originCategories: {
      type: Array,
      required: true
    },
    originSites: {
      type: Array,
      required: true
    },
    brandsProp: {
      type: Array,
      required: true
    },
    salesmen: {
      type: Array,
      required: true
    },
    loadingProp: {
      type: Boolean,
      default: false
    },
    siteId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      showFilter: false,
      selected_brands: [],
      selected_sites: [],
      selected_tlds: [],
      selected_categories: [],
      selected_salesmen: [],
      loading: true,
      datacollection: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        tooltips: {
          intersect: false,
          mode: "index",
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }
              label +=
                data.datasets[tooltipItem.datasetIndex].data_formated[
                  tooltipItem.index
                ];
              return label;
            }
          }
        }
      },
      chart_colors: ["#EBCFC3", "#395E7D", "#BDB990", "#DAEFDF"],
      date_filter: {
        from: null,
        to: null
      },
      date_filter_compare: null,

      selected_data_type: "gross_revenue",
      data_types: [
        {
          text: this.$t("gross_revenue"),
          id: "gross_revenue"
        },
        {
          text: this.$tc("order", 2),
          id: "order"
        },
        {
          text: this.$t("gross_margin"),
          id: "gross_margin"
        },
        {
          text: this.$t("avg_cart"),
          id: "avg_cart"
        },
        {
          text: this.$t("shipping"),
          id: "shipping"
        },
        {
          text: this.$t("quantity"),
          id: "quantity"
        },
        {
          text: this.$t("discount"),
          id: "discount"
        }
      ],
      data_types_obj: {
        gross_revenue: "subtotals",
        order: "num_orders",
        gross_margin: "margins",
        avg_cart: "avg_carts",
        shipping: "shipping_amounts",
        discount: "discounts",
        quantity: "quantity"
      },

      warehouse_id: null,

      selected_date_mode: "daily",
      dateModes: [
        {
          text: this.$t("hourly"),
          value: "hourly"
        },
        {
          text: this.$t("daily"),
          value: "daily"
        },
        {
          text: this.$t("weekly"),
          value: "weekly"
        },
        {
          text: this.$t("monthly"),
          value: "monthly"
        },
        {
          text: this.$t("yearly"),
          value: "yearly"
        }
      ]
    };
  },
  watch: {
    loadingProp(newVal) {
      this.loading = newVal;
      if (!newVal) {
        this.fillData();
      }
    },
    selected_data_type() {
      this.fillData();
    },
    selected_date_mode() {
      this.emitFilter();
    },
    showFilter(newVal) {
      if (!newVal) this.initFilters();
    }
  },
  created() {
    this.fillData();
  },
  methods: {
    setFilter(obj) {
      this.selected_brands = obj.brands;
      this.selected_sites = obj.sites;
      this.selected_tlds = obj.tlds;
      this.selected_categories = obj.categories;
      this.selected_salesmen = obj.salesmen;
      this.warehouse_id = obj.warehouse_id || this.warehouse_id;
      this.emitFilter();
    },
    emitFilter: debounce(function() {
      this.$emit("getData", {
        brands: this.selected_brands,
        sites: this.selected_sites,
        tlds: this.selected_tlds,
        categories: this.selected_categories,
        salesmen: this.selected_salesmen,
        date: this.date_filter,
        compare_date: this.date_filter_compare,
        data_type: this.selected_data_type,
        warehouse_id: this.warehouse_id,
        selected_date_mode: this.selected_date_mode
      });
    }, 1000),
    fillData() {
      let datacollection = {
        labels: this.periods,
        datasets: []
      };
      let selectedType = this.data_types_obj[this.selected_data_type];
      for (let i = 0; i < this.stats.length; i++) {
        const stat = this.stats[i];
        let obj = {
          label: stat.label,
          backgroundColor: this.chart_colors[i],
          borderColor: this.chart_colors[i],
          fill: false
        };
        if (i == 0) {
          obj.fill = true;
          // obj.backgroundColor = "rgba(9 , 57 , 94, .1)";
          obj.backgroundColor = "#ebcfc352";
        }
        let data = [];
        let data_formated = [];
        for (let i = 0; i < stat[selectedType].length; i++) {
          const el = stat[selectedType][i];
          data.push(el.value);
          data_formated.push(el.formatted);
        }
        obj.data = data;
        obj.data_formated = data_formated;
        if (this.stats.length == 1) {
          obj.label = this.$tc(this.selected_data_type, 2);
        }
        datacollection.datasets.push(obj);
      }
      this.datacollection = datacollection;
    },
    changeDates(dates) {
      this.date_filter.from = dates.date.from;
      this.date_filter.to = dates.date.to;
      if (
        (this.date_filter_compare && !dates.compare_date) ||
        (!this.date_filter_compare && dates.compare_date)
      ) {
        this.initFilters();
      }
      if (dates.compare_date) {
        this.date_filter_compare = {};
        this.date_filter_compare.from = dates.compare_date.from;
        this.date_filter_compare.to = dates.compare_date.to;
      } else {
        this.date_filter_compare = null;
      }
      this.emitFilter();
    },
    initFilters() {
      this.selected_brands = [];
      this.selected_sites = [];
      this.selected_tlds = [];
      this.selected_categories = [];
      this.selected_salesmen = [];
      this.warehouse_id = [];
    }
  }
};
</script>
