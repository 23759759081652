<template>
  <div class="columns is-mobile is-multiline">
    <div class="column is-6-tablet is-12-mobile">
      <tree-select
        :placeholder="$t('site')"
        size="is-small"
        :disabled="!!siteId"
        :selected="siteId"
        :items="sites"
        :value="selected_sites_value"
        open-all
        @change="setSelectedSites"
      />
    </div>
    <div class="column is-6-tablet is-12-mobile">
      <tree-select
        :placeholder="$tc('category', 1)"
        size="is-small"
        :items="categories"
        :value="selected_categories"
        @change="setSelectedCategories"
      />
    </div>
    <div class="column is-6-tablet is-12-mobile">
      <b-autocomplete-validation
        v-model="selected_brands"
        size="is-small"
        :label="$tc('brand', 2)"
        :data="brands"
        :placeholder="$t('choose')"
        vid="brand"
        :disabled="brands.length <= 1"
        item-text="label"
        field="id"
        :clearable="brands.length > 1"
        hide-details
      />
    </div>
    <div class="column is-3-tablet is-6-mobile">
      <b-autocomplete-validation
        v-model="selected_salesman"
        size="is-small"
        :label="$tc('salesman', 1)"
        :data="salesmen"
        :placeholder="$t('choose')"
        vid="salesman"
        :disabled="salesmen.length <= 1"
        item-text="name"
        field="id"
        :clearable="salesmen.length > 1"
        hide-details
      />
    </div>
    <div class="column is-3-tablet is-6-mobile">
      <b-select-validation
        v-model="warehouse_id"
        size="is-small"
        required
        :label="$tc('warehouse', 1)"
        hide-details
      >
        <template>
          <option
            v-for="opt in warehouses"
            :key="opt.id"
            :value="opt.id"
          >
            {{ opt.country.country }} - {{ opt.currency.code }}
          </option>
        </template>
      </b-select-validation>
    </div>
  </div>
</template>

<script>
import treeSelect from "@/components/forms/elements/treeSelect";

export default {
  components: {
    treeSelect
  },
  props: {
    originSites: {
      type: Array,
      required: true
    },
    originCategories: {
      type: Array,
      required: true
    },
    brandsProp: {
      type: Array,
      required: true
    },
    salesmenProp: {
      type: Array,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
    siteId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      cancelEmit: false,
      sites: [],
      brands: [],
      categories: [],
      salesmen: [],

      // seleccted items
      selected_brands: null,
      selected_sites_value: [],
      selected_sites: [],
      selected_tlds: [],
      selected_categories: [],
      selected_salesman: null,
      warehouse_id: this.warehouses[0].id
    };
  },
  watch: {
    brands(newVal, oldVal) {
      if (newVal.length == 1 && newVal.length != oldVal.length) {
        this.cancelEmit = true;
        this.selected_brands = newVal[0].label;
      }
    },
    selected_brands() {
      this.emitChange();
    },
    selected_sites(newVal, oldVal) {
      if (oldVal == newVal) this.cancelEmit = true;
      if (JSON.stringify(newVal) != JSON.stringify(oldVal) || newVal == []) {
        this.emitChange();
      }
    },
    selected_categories(newVal, oldVal) {
      if (oldVal == newVal) this.cancelEmit = true;
      if (JSON.stringify(newVal) != JSON.stringify(oldVal) || newVal == []) {
        this.emitChange();
      }
    },
    selected_salesman() {
      this.emitChange();
    },
    warehouse_id() {
      this.emitChange();
    }
  },
  mounted() {
    if (this.siteId) this.selected_sites_value = [this.siteId];
    this.brands = this.brandsProp;
    this.salesmen = this.salesmenProp.reduce(
      (arr, s) => [...arr, { ...s, name: `${s.fname} ${s.lname}` }],
      []
    );
    this.sites = this.setSites(this.originSites);
    this.categories = this.setCategories(this.originCategories);
  },
  methods: {
    emitChange() {
      if (this.cancelEmit) {
        return (this.cancelEmit = false);
      }
      this.$emit("update", {
        brands: this.selected_brands ? [this.selected_brands] : null,
        sites: this.selected_sites,
        tlds: this.selected_tlds,
        categories: this.selected_categories,
        salesmen: this.selected_salesman ? [this.selected_salesman] : null,
        warehouse_id: this.warehouse_id
      });
    },
    setSelectedCategories(arr) {
      this.selected_categories = arr;
    },
    setSelectedSites(arr) {
      this.selected_sites = arr;
    },
  
    // setting data for compoenents props
    setSites(sites) {
      let arr = [];
      for (let i = 0; i < sites.length; i++) {
        const site = sites[i];
        let obj = {
          name: site.label,
          label: site.label,
          id: site.id,
          children: []
        };
        for (let j = 0; j < site.tlds.length; j++) {
          const tld = site.tlds[j];
          obj.children.push({
            name: tld.country.label,
            id: `-${tld.sld_id}`,
            label: `${site.label}-${tld.country.label}`,
          });
        }
        arr.push(obj);
      }
      return arr;
    },
    setCategories(categories) {
      let arr = [];
      for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        let obj = {
          name: category.label,
          label: category.label,
          id: category.id,
          children: []
        };
        for (let j = 0; j < category.children.length; j++) {
          const subCategory = category.children[j];
          let subObj = {
            name: subCategory.label,
            id: subCategory.id,
            label: subCategory.label,
            children: []
          };
          if (subCategory.children) {
            for (let k = 0; k < subCategory.children.length; k++) {
              const subCategory_2 = subCategory.children[k];
              subObj.children.push({
                name: subCategory_2.label,
                id: subCategory_2.id,
                label: subCategory_2.label
              });
            }
          }
          obj.children.push(subObj);
        }
        arr.push(obj);
      }

      return arr;
    }
  }
};
</script>
